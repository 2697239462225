// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buildingdetailpage-js": () => import("./../src/pages/buildingdetailpage.js" /* webpackChunkName: "component---src-pages-buildingdetailpage-js" */),
  "component---src-pages-favorites-js": () => import("./../src/pages/favorites.js" /* webpackChunkName: "component---src-pages-favorites-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-listing-detail-page-js": () => import("./../src/pages/listingDetailPage.js" /* webpackChunkName: "component---src-pages-listing-detail-page-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-marketinsights-js": () => import("./../src/pages/marketinsights.js" /* webpackChunkName: "component---src-pages-marketinsights-js" */),
  "component---src-pages-mylistings-js": () => import("./../src/pages/mylistings.js" /* webpackChunkName: "component---src-pages-mylistings-js" */),
  "component---src-pages-search-js": () => import("./../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */)
}

